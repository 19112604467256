import type { QueryParams } from '~/types/api'
import type {
  PriceCategory,
  PriceCategoryPagedResult,
  PriceDetail,
  PricesPagedResult,
} from '~/types/api'

export function createPricesRepository() {
  const { $api } = useNuxtApp()
  const { get } = baseRepository($api)

  const DOMAIN_PRICES = 'prices'
  const DOMAIN_CATEGORIES = 'price-categories'

  async function getAll(params?: QueryParams) {
    return get<PricesPagedResult>([DOMAIN_PRICES], { params })
  }

  async function getByNid(nid: number) {
    return get<PriceDetail>([DOMAIN_PRICES, nid.toString()])
  }

  async function getAllCategories(params?: QueryParams) {
    return get<PriceCategoryPagedResult>([DOMAIN_CATEGORIES], {
      params,
    })
  }

  async function getCategory(id: number) {
    return get<PriceCategory>([DOMAIN_CATEGORIES, id.toString()])
  }

  return {
    getAll,
    getByNid,
    getAllCategories,
    getCategory,
  }
}
