import { defineStore } from 'pinia'
import type {
  CustomerGroup,
  PriceCategory,
  PriceCategoryPagedResult,
} from '~/types/api'
import type { AutocompleteItem } from '~/types/form'
import { APIFilters, getStatusFilter } from '~/utils/APIFilters'
import type { QueryParams } from '~/types/api'

export const usePriceCategories = defineStore('priceCategories', () => {
  const pricesRepository = createPricesRepository()

  const { loadList } = useCache()

  const fetchedPriceCategories = ref<PriceCategory[]>([])
  const priceCategoriesLoading = ref(false)

  const priceCategories = computed(() => {
    return fetchedPriceCategories.value.map(
      (priceCategory: PriceCategory) =>
        ({
          value: priceCategory.nid,
          text: priceCategory.title,
        }) as AutocompleteItem
    )
  })

  const priceCategoryPromise = ref<Promise<CustomerGroup[]> | null>(null)
  function loadPriceCategories(): Promise<PriceCategory[]> {
    const params = {
      filter: APIFilters.makeFilter(getStatusFilter()),
      sort: APIFilters.makeSort({ nid: 'DESC' }),
    } as QueryParams

    const apiCall = pricesRepository.getAllCategories.bind(
      pricesRepository,
      params
    )
    return loadList<PriceCategory, PriceCategoryPagedResult>(
      fetchedPriceCategories,
      priceCategoryPromise,
      priceCategoriesLoading,
      apiCall
    )
  }

  return {
    fetchedPriceCategories,
    priceCategoriesLoading,
    priceCategories,
    loadPriceCategories,
  }
})
