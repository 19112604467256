<script setup lang="ts">
import { storeToRefs } from 'pinia'
import type { DropdownItem } from '#ui/types'
import { useCustomerGroups } from '~/stores/customerGroups'
import { usePriceCategories } from '~/stores/priceCategories'
import { useUserConfig } from '~/stores/userConfig'
import type { AutocompleteItem } from '~/types/form'

const customerGroupsStore = useCustomerGroups()
const priceCategoriesStore = usePriceCategories()

await customerGroupsStore.loadCustomerGroups()
await priceCategoriesStore.loadPriceCategories()

const { customerGroups } = storeToRefs(customerGroupsStore)
const { priceCategories } = storeToRefs(priceCategoriesStore)

const userConfigStore = useUserConfig()
const { customerGroupId, priceCategoryId } = storeToRefs(userConfigStore)

const { t } = useI18n()

const priceCategoryItems = computed(() => {
  return priceCategories.value.map(
    (priceCategory: AutocompleteItem) =>
      ({
        label: priceCategory.text,
        click: getSelectPriceCategoryHandler(priceCategory),
      }) as DropdownItem
  )
})

const priceCategoryLangPath = 'base.header.priceSettings.priceCategory'
const priceCategoryLabel = ref<string>(t(priceCategoryLangPath))
if (priceCategoryId.value !== null) {
  priceCategoryLabel.value =
    priceCategories.value.find((pc) => pc.value === priceCategoryId.value)
      ?.text ?? t(priceCategoryLangPath)
}

function getSelectPriceCategoryHandler(priceCategory?: AutocompleteItem) {
  return () => {
    userConfigStore.setPriceCategory(priceCategory?.value ?? null)
    priceCategoryLabel.value = priceCategory?.text ?? ''
  }
}

const customerGroupItems = computed(() => {
  return customerGroups.value.map(
    (customerGroup: AutocompleteItem) =>
      ({
        label: customerGroup.text,
        click: getSelectCustomerGroupHandler(customerGroup),
      }) as DropdownItem
  )
})

const customerGroupLangPath = 'base.header.priceSettings.customerGroup'
const customerGroupLabel = ref<string>(t(customerGroupLangPath))
if (customerGroupId.value !== null) {
  customerGroupLabel.value =
    customerGroups.value.find((cg) => cg.value === customerGroupId.value)
      ?.text ?? t(customerGroupLangPath)
}

function getSelectCustomerGroupHandler(customerGroup?: AutocompleteItem) {
  return () => {
    userConfigStore.setCustomerGroup(customerGroup?.value ?? null)
    customerGroupLabel.value = customerGroup?.text ?? ''
  }
}
</script>

<template>
  <div>
    <div class="flex w-full flex-row flex-wrap gap-2 sm:flex-nowrap">
      <div class="w-1/2">
        <UDropdown
          :items="[priceCategoryItems]"
          :popper="{ placement: 'bottom-start' }"
          :ui="{
            base: 'dropdown-nudged',
            ring: 'ring-1 ring-black',
            rounded: '',
          }"
          @click.stop
        >
          <button
            class="w-60 border border-black bg-white px-3 py-1 hover:bg-neutral-100"
          >
            <div class="flex justify-between">
              <div class="whitespace-nowrap">
                {{ priceCategoryLabel }}
              </div>
              <Icon class="ml-16 mt-1" name="fa6-solid:angle-down" />
            </div>
          </button>
        </UDropdown>
      </div>
      <div class="w-1/2">
        <UDropdown
          :items="[customerGroupItems]"
          :popper="{ placement: 'bottom-start' }"
          :ui="{
            base: 'dropdown-nudged',
            ring: 'ring-1 ring-black',
            rounded: '',
          }"
          @click.stop
        >
          <button
            class="w-60 border border-black bg-white px-3 py-1 hover:bg-neutral-100"
          >
            <div class="flex justify-between">
              <div class="whitespace-nowrap">
                {{ customerGroupLabel }}
              </div>
              <Icon class="ml-16 mt-1" name="fa6-solid:angle-down" />
            </div>
          </button>
        </UDropdown>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
